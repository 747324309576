class ZIDXAccountSiteSmtpVerify implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSiteSmtpVerifyContainer";
    }
    getMatchURL(){
        return "/site/smtp/verify";
    }
    render(){
        // console.log("login");
    }
}